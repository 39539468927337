import React from "react"



export default function ErrorPage() {
  return (

    <div>
Page not found    
    </div>
  )

}
